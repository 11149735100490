<template>
  <div>
    <section id="investor" class="main-banner">
      <div class="container align-items-center">
        <div
          data-aos="fade-up"
          data-aos-easing="ease-in-back"
          data-aos-duration="500"
        >
          <div class="col-md-12">
            <h1 class="v-bios-heading">{{$t('Investor Portal')}}</h1>
          </div>
        </div>
      </div>
    </section>
    
      <div class="container align-items-center">
        <div class="col-md-12 mt-5 mb-5">
          <div v-for="(f, k) in files" :key="'category_'+k">
            <h2>{{k}}</h2>
            <card v-for="(file) in f" :key="'file_'+file.id" class="mt-3 mb-3">
              <div class="card-body">
                <h5 class="card-title">
                  <a class="card-link" :href="file.url + '&token=' + getCookie('token')">{{file.name}}</a>
                </h5>
                <h6 class="card-subtitle text-muted">{{file.created_at.split(' ')[0]}}</h6>
              </div>
            </card>
          </div> 
        </div>
      </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from 'vuex'
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  middleware: 'auth',
  layout: "home",

  metaInfo () {
    return { title: this.$t('home') }
  },

  data: () => ({
    files: {},
  }),

  computed: mapGetters({
    locale: 'lang/locale',
  }),

  watch: {
    'locale': function(){
      this.getInvestorFiles().then(files => this.files = files)
    }
  },

  mounted(){
      AOS.init();
      window.AOS = AOS;
    this.getInvestorFiles().then(files => {
      this.files = files
    })
  },

  methods: {
     getCookie: function(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    getInvestorFiles: async function () {
      const { data } = await axios.get(`${window.config.BASE_PATH}/api/investor_files?lang=${this.locale}`);
      if (data && data.records) {
        var files = {}
        data.records.forEach(r => {
          if(this.locale == 'zh-HK'){
            if(r.category_hk == '') return
            if(!files[r.category_hk]) files[r.category_hk] = []
            files[r.category_hk].push(r)
          } else if(this.locale == 'zh-CN'){
            if(r.category_cn == '') return
            if(!files[r.category_cn]) files[r.category_cn] = []
            files[r.category_cn].push(r)
          } else {
            if(r.category == '') return
            if(!files[r.category]) files[r.category] = []
            files[r.category].push(r)
          }
        });
        return files;
      }
      else return {};
    },
  }


}
</script>
<style scoped>
.main-banner {
  background: rgba(27, 78, 130, 1);
  height: 30vh;
}
.v-bios-heading {
  margin-top: 13vh;
  color: #FFF;
}
</style>